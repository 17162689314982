import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/styles/main.css'

// Import our lazy loading utility
import { registerLazyLoading } from './utils/imageLazyLoading'

// Create the app instance
const app = createApp(App)

// Register our custom directives
registerLazyLoading(app)

// Mount the app with router
app.use(router).mount('#app')
