/**
 * Image Lazy Loading Utility
 * 
 * This utility provides functions for lazy loading images and implementing
 * the IntersectionObserver API for efficient image loading.
 */

// Default placeholder image - a very small, lightweight SVG
const PLACEHOLDER_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAwIiBoZWlnaHQ9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMzAwIiBoZWlnaHQ9IjMwMCIgZmlsbD0iI2YwZjBmMCIgLz48L3N2Zz4=';

/**
 * Creates and configures a new IntersectionObserver
 * @param {Function} loadCallback - Function to call when element enters viewport
 * @param {Object} options - IntersectionObserver options
 * @returns {IntersectionObserver} - Configured IntersectionObserver
 */
export const createObserver = (loadCallback, options = {}) => {
  const defaultOptions = {
    root: null, // viewport
    rootMargin: '50px 0px', // load when within 50px of viewport
    threshold: 0.1 // 10% visibility triggers load
  };

  const observerOptions = { ...defaultOptions, ...options };
  
  // Create and return IntersectionObserver
  return new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      // If element is in view
      if (entry.isIntersecting) {
        loadCallback(entry.target, observer);
      }
    });
  }, observerOptions);
};

/**
 * Vue directive for lazy loading images
 * Usage: v-lazy-img="'/path/to/actual/image.jpg'"
 */
export const lazyImageDirective = {
  mounted(el, binding) {
    // Set the placeholder initially
    if (el.tagName === 'IMG') {
      el.src = PLACEHOLDER_IMAGE;
      el.classList.add('lazy-image');
      el.setAttribute('data-src', binding.value);
    } else {
      // For background images
      el.style.backgroundImage = `url(${PLACEHOLDER_IMAGE})`;
      el.classList.add('lazy-background');
      el.setAttribute('data-background-src', binding.value);
    }

    // Create observer to load actual image
    const observer = createObserver((target, obs) => {
      setTimeout(() => { // Small delay for better perceived performance
        if (target.tagName === 'IMG') {
          // Create a new image to preload
          const img = new Image();
          img.onload = () => {
            target.src = target.getAttribute('data-src');
            target.classList.add('lazy-loaded');
          };
          img.src = target.getAttribute('data-src');
        } else {
          // For background images
          target.style.backgroundImage = `url(${target.getAttribute('data-background-src')})`;
          target.classList.add('lazy-loaded');
        }
        obs.unobserve(target);
      }, 100);
    });

    // Start observing the element
    observer.observe(el);
  }
};

/**
 * Initialize and register the lazy-load directive with Vue
 * @param {Object} app - Vue app instance
 */
export const registerLazyLoading = (app) => {
  app.directive('lazy-img', lazyImageDirective);
};

// Default export for convenience
export default {
  registerLazyLoading,
  lazyImageDirective,
  createObserver,
  PLACEHOLDER_IMAGE
};